import './jquery.mjs';
import 'jquery-ui/ui/version';
import 'jquery-ui/ui/data';
import 'jquery-ui/ui/disable-selection';
import 'jquery-ui/ui/labels';
import 'jquery-ui/ui/jquery-patch';
import 'jquery-ui/ui/plugin';
import 'jquery-ui/ui/keycode';
import 'jquery-ui/ui/scroll-parent';
import 'jquery-ui/ui/tabbable';
import 'jquery-ui/ui/unique-id';
import 'jquery-ui/ui/position';
import 'jquery-ui/ui/disable-selection';
import 'jquery-ui/ui/focusable';
import 'jquery-ui/ui/form-reset-mixin';
import 'jquery-ui/ui/keycode';
import 'jquery-ui/ui/labels';
import 'jquery-ui/ui/scroll-parent';
import 'jquery-ui/ui/tabbable';
import 'jquery-ui/ui/unique-id';

import 'jquery-ui/ui/widget';
import 'jquery-ui/ui/widgets/mouse';
import 'jquery-ui/ui/widgets/accordion';
import 'jquery-ui/ui/widgets/autocomplete';
import 'jquery-ui/ui/widgets/button';
import 'jquery-ui/ui/widgets/checkboxradio';
import 'jquery-ui/ui/widgets/controlgroup';
import 'jquery-ui/ui/widgets/dialog';
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/droppable';
import 'jquery-ui/ui/widgets/menu';
import 'jquery-ui/ui/widgets/progressbar';
import 'jquery-ui/ui/widgets/resizable';
import 'jquery-ui/ui/widgets/selectable';
import 'jquery-ui/ui/widgets/selectmenu';
import 'jquery-ui/ui/widgets/slider';
import 'jquery-ui/ui/widgets/sortable';
import 'jquery-ui/ui/widgets/spinner';
import 'jquery-ui/ui/widgets/tabs';

import 'jquery-ui/ui/effect';
import 'jquery-ui/ui/effects/effect-blind';
import 'jquery-ui/ui/effects/effect-bounce';
import 'jquery-ui/ui/effects/effect-clip';
import 'jquery-ui/ui/effects/effect-drop';
import 'jquery-ui/ui/effects/effect-explode';
import 'jquery-ui/ui/effects/effect-fade';
import 'jquery-ui/ui/effects/effect-fold';
import 'jquery-ui/ui/effects/effect-highlight';
import 'jquery-ui/ui/effects/effect-puff';
import 'jquery-ui/ui/effects/effect-pulsate';
import 'jquery-ui/ui/effects/effect-scale';
import 'jquery-ui/ui/effects/effect-shake';
import 'jquery-ui/ui/effects/effect-size';
import 'jquery-ui/ui/effects/effect-slide';
import 'jquery-ui/ui/effects/effect-transfer';

import 'jquery-ui/dist/themes/base/jquery-ui.css';

setModuleReady('jquery-ui');
